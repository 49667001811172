













































































































import { defineComponent, computed } from '@vue/composition-api'
import { Intro, CardBlog, CardItem } from '@/inc/types'

export default defineComponent({
  name: 'GIntro',
  props: {
    content: {
      type: Object as () => Intro,
      default: {} as Intro,
    },
    items: {
      type: Array as () => CardBlog[],
      default: () => [],
    },
  },

  setup(props) {
    // Create a computed property for the items.
    const remapedItems = computed(() =>
      props.items.map(item => {
        const news: CardItem = {
          title: item.newsLabel,
          url: item?.link?.url,
          logo: item.logo,
          date: item.publicationDate,
        }

        console.log('remapedItems', item)

        return news
      })
    )

    return {
      remapedItems,
    }
  },
})
